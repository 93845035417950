<template>
    <div>
        <v-chart class="chart" :option="option" />
    </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
    CanvasRenderer,
    PieChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
]);

import useGeneratePDF from "@/composables/generatePDF.js";

export default {
    name: "Chart",
    props: { data: Array, chartOptions: Object },
    components: {
        VChart,
    },
    // provide: {
    //  [THEME_KEY]: "dark",
    // },
    setup() {
        const { generatePDF } = useGeneratePDF();
        return { generatePDF };
    },
    data() {
        return {
            option: this.chartOptions,
        };
    },
    beforeMount() {},
    methods: {},
};
</script>

<style lang="sass" scoped>
.chart
  height: 700px
  // width: 700px !important
  margin: 0 auto
</style>
