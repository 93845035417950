<template>
  <section class="pd-20">
    <a-collapse
      class="travel-filters expandable mt-0 mb-30"
      activeKey="0"
      expandIconPosition="right"
    >
      <a-collapse-panel key="1">
        <template slot="header">
          <a-icon type="filter" class="mr-5" /> FILTRAR
        </template>
        <a-row class="mt-0" :gutter="20">
          <a-col :span="6">
            <div class="travel-input">
              <label for class="filled">Período</label>
              <a-range-picker
                v-model="filters.period.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
                @change="getContracts()"
              />
            </div>
          </a-col>

          <a-col :span="6">
            <div class="travel-input">
              <label for class="filled">Categorias do Produto</label>
              <a-select
                class="travel-input"
                placeholder="Selecione uma categoria"
                mode="multiple"
                v-model="filters.productsCategory.selected"
                show-search
                style="width: 100%"
                @change="getContracts()"
              >
                <a-select-option
                  v-for="(item, index) of filters.productsCategory.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>

          <a-col :span="6">
            <div class="travel-input">
              <label for class="filled">Tipo de venda</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                mode="multiple"
                v-model="filters.saleType.selected"
                show-search
                style="width: 100%"
                @change="getContracts()"
              >
                <a-select-option
                  v-for="(item, index) of filters.saleType.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>

          <a-col :span="6" style="display: none">
            <div class="travel-input">
              <label for class="filled">Status</label>
              <a-select
                class="travel-input"
                placeholder="Selecione o status do contrato"
                mode="multiple"
                v-model="filters.status.selected"
                show-search
                style="width: 100%"
                @change="getContracts()"
              >
                <a-select-option
                  v-for="(item, index) of filters.status.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>

    <div v-if="generateChart">
      <div class="a-right">
        <a-button
          class="ml-10"
          @click="generatePDF()"
          size="default"
          type="danger"
          :loading="generatePDFLoading"
          ghost
        >
          <a-icon type="file-pdf" /> PDF
        </a-button>
      </div>

      <div id="print-area">
        <Chart
          v-if="generateSuppliersData().chartArr.length > 0"
          :chartOptions="chartOptions"
        />

        <div class="extra">
          <a-table
            class="chart-table mt-20"
            :columns="[
              {
                title: 'Nome',
                scopedSlots: { customRender: 'name' },
                width: 340,
              },
              {
                title: 'Vendas',
                scopedSlots: { customRender: 'value' },
                sorter: true,
                defaultSortOrder: 'descend',
                width: 120,
                sorter: (a, b) => a.value - b.value,
              },
              {
                title: '%',
                scopedSlots: { customRender: 'percentage' },
                width: 120,
              },
              {
                title: 'Valor Venda',
                scopedSlots: { customRender: 'total' },
                width: 120,
              },
              {
                title: 'Valor Net',
                scopedSlots: { customRender: 'netValue' },
                width: 120,
              },

              {
                title: 'Lucratividade',
                scopedSlots: { customRender: 'profit' },
                width: 120,
              },
              {
                title: 'Ticket Médio (Valor Venda)',
                scopedSlots: { customRender: 'averageTicket' },
                sorter: true,
                width: 120,
                sorter: (a, b) => a.averageTicket - b.averageTicket,
              },
            ]"
            :data-source="generateSuppliersData().chartArr"
            :loading="loading"
            :pagination="false"
          >
            <template slot="name" slot-scope="record">
              {{ record.name }}
            </template>

            <template slot="averageTicket" slot-scope="record">
              {{ formatPricePtBr(record.averageTicket) }}
            </template>
            <template slot="percentage" slot-scope="record">
              {{
                formatPercentage(
                  (100 * record.value) / generateSuppliersData().totalQuantity
                )
              }}
            </template>
            <template slot="netValue" slot-scope="record">
              {{ formatPricePtBr(record.netValue) }}
            </template>
            <template slot="total" slot-scope="record">
              {{ formatPricePtBr(record.total) }}
            </template>
            <template slot="profit" slot-scope="record">
              {{ formatPricePtBr(record.profit) }}
            </template>

            <template slot="value" slot-scope="record">
              {{ record.value }}
            </template>
            <template slot="footer"> </template>
          </a-table>

          <a-table
            class="chart-table mt-0"
            :columns="[
              {
                title: 'Nome',
                scopedSlots: { customRender: 'name' },
                width: 340,
              },
              {
                title: 'Vendas',
                scopedSlots: { customRender: 'quantity' },
                width: 120,
              },
              {
                title: '%',
                scopedSlots: { customRender: 'percentage' },
                width: 120,
              },
              {
                title: 'Valor Venda',
                scopedSlots: { customRender: 'total' },
                width: 120,
              },
              {
                title: 'Valor Net',
                scopedSlots: { customRender: 'netValue' },
                width: 120,
              },

              {
                title: 'Lucratividade',
                scopedSlots: { customRender: 'profit' },
                width: 120,
              },
              {
                title: 'Ticket Médio (Valor Venda)',
                scopedSlots: { customRender: 'averageTicket' },
                width: 120,
              },
            ]"
            :data-source="generateSuppliersDataTotal()"
            :loading="loading"
            :showHeader="false"
            :pagination="false"
          >
            <template slot="name" slot-scope="record">
              <b>{{ record.name }}</b>
            </template>

            <template slot="quantity" slot-scope="record">
              <b> {{ record.quantity }}</b>
            </template>

            <template slot="percentage" slot-scope="record">
              <b> {{ record.percentage }}</b>
            </template>

            <template slot="total" slot-scope="record">
              <b> {{ formatPricePtBr(record.total) }}</b>
            </template>

            <template slot="netValue" slot-scope="record">
              <b> {{ formatPricePtBr(record.netValue) }}</b>
            </template>

            <template slot="profit" slot-scope="record">
              <b> {{ formatPricePtBr(record.profit) }}</b>
            </template>

            <template slot="averageTicket" slot-scope="record">
              <b> {{ formatPricePtBr(record.averageTicket) }}</b>
            </template>
          </a-table>

          <a-alert
            class="mt-10"
            type="info"
            message="Observação: O Custo Financeiro não foi contabilizado."
            banner
            style="font-size: 12px"
          />
        </div>
      </div>
    </div>
    <div v-if="!generateChart">
      <a-skeleton active />
      <a-skeleton active />
      <a-skeleton active />
    </div>
  </section>
</template>

<script>
import _ from "lodash";
import html2pdf from "html2pdf.js";
import { format } from "date-fns";
import { parse } from "vue-currency-input";

import formatThings from "@/mixins/general/formatThings.js";
import customerMixins from "@/mixins/customers/customerMixins.js";
import useGeneratePDF from "@/composables/generatePDF.js";

import Chart from "@/components/general/Chart.vue";

export default {
  name: "ContractSuppliersReport",
  mixins: [formatThings, customerMixins],
  components: {
    Chart,
  },
  setup() {
    const { generatePDF } = useGeneratePDF();
    return { generatePDF };
  },
  data() {
    return {
      generateChart: false,
      chartInfo: { title: "Ranking de Fornecedores", subtitle: "" },
      chartOptions: {
        title: {
          text: "Ranking de Fornecedores",
          subtext: "",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{c} - {b} ({d}%)",
        },
        series: [
          {
            name: "Nome:",
            type: "pie",
            radius: "55%",
            data: [],
            emphasis: {
              itemStyle: {
                shadowOffsetX: 0,
              },
            },
          },
        ],
      },
      filters: {
        loadingResults: false,
        id: "",
        paymentMethods: {
          list: [
            {
              label: "Cartão de Crédito",
              value: "credit-card",
            },
            {
              label: "Boleto",
              value: "billing-ticket",
            },
            {
              label: "Transferência Bancária",
              value: "bank-transfer",
            },
            {
              label: "Carta de Crédito",
              value: "payment-card",
            },
            {
              label: "Link de Pagamento",
              value: "payment-link",
            },
          ],
          selected: [],
        },
        companies: {
          list: [
            {
              label: "Viajar Resorts",
              value: "Viajar Resorts",
            },
            {
              label: "Voe Simples",
              value: "Voe Simples",
            },
            {
              label: "Credimilhas",
              value: "Credimilhas",
            },
          ],
          selected: [],
        },
        personTypes: {
          list: [
            {
              label: "Pessoa Física",
              value: "Pessoa Física",
            },
            {
              label: "Pessoa Jurídica",
              value: "Pessoa Jurídica",
            },
          ],
          selected: [],
        },
        users: {
          list: [],
          selected: [],
        },
        productsCategory: {
          list: [
            {
              label: "Hotéis/Resorts",
              value: "hotel",
            },
            {
              label: "Aéreo",
              value: "flight",
            },
            {
              label: "Serviços",
              value: "service",
            },
          ],
          selected: [],
        },
        saleType: {
          list: [
            {
              label: "Pacote Completo",
              value: "Pacote Completo Terceiros",
            },
            {
              label: "Serviços",
              value: "Serviços",
            },
          ],
          selected: [],
        },
        products: {
          list: [],
          selected: [],
        },
        clients: {
          list: [],
          selected: [],
        },
        status: {
          list: [
            {
              label: "Concluído",
              value: "concluded",
            },
            {
              label: "Em edição",
              value: "edit",
            },
            {
              label: "Cancelado",
              value: "canceled",
            },
          ],
          selected: ["concluded"],
        },
        embark: {
          selected: [],
        },
        period: {
          selected: [],
        },
        searchTerm: "",
        price: {
          min: "",
          max: "",
        },
        order: "desc",
        orderBy: "created",
      },
      pagination: {
        page: 1,
        perPage: 500,
        total: 0,
        totalPages: 0,
      },
      contractList: [],
      loading: false,
    };
  },
  computed: {
    routeTab() {
      return {
        title: `Ranking de Fornecedores - Contratos`,
        icon: "line-chart",
        tips: "",
      };
    },
  },
  beforeMount() {
    document.title = "Ranking de Fornecedores - Contratos";

    let date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    let firstDay = new Date(y, m, 1);
    let lastDay = new Date(y, m + 1, 0);

    firstDay = format(firstDay, "yyyy-MM-dd");
    lastDay = format(lastDay, "yyyy-MM-dd");

    this.filters.period.selected = [`${firstDay}`, `${lastDay}`];

    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get("period")) {
      this.filters.period.selected = urlParams.get("period").split("|");
    }

    this.getContracts();
    this.getUsers();
  },
  methods: {
    generateSuppliersDataTotal() {
      let arr = [];

      arr.push({
        name: "TOTAL",
        quantity: this.generateSuppliersData().totalQuantity,
        percentage: "100%",
        total: this.generateSuppliersData().totalSale,
        netValue: this.generateSuppliersData().totalNetValue,
        profit: this.generateSuppliersData().totalProfit,
        averageTicket: this.generateSuppliersData().totalValue,
      });

      return arr;
    },
    generateSuppliersData() {
      let supplierlArr = [],
        newArr = [],
        chartArr = [],
        totalQuantity = 0,
        totalNetValue = 0,
        totalProfit = 0,
        totalSale = 0,
        totalValue = 0;

      if (this.contractList.length > 0) {
        this.contractList.forEach((contract) => {
          if (this.filters.productsCategory.selected.length > 0) {
            if (contract.meta.package_type == "Pacote Completo Terceiros") {
              supplierlArr.push({
                id: contract.id,
                name: `PACOTE COMPLETO`,
                value: contract.meta[`package_total_price`],
                netValue: contract.meta[`contract_finances_package_net_value`],
                profit: this.parseFloatToStr(
                  parse(contract.meta[`package_total_price`]) -
                    parse(contract.meta[`contract_finances_package_net_value`])
                ),
              });
            } else {
              if (this.filters.productsCategory.selected.includes("hotel")) {
                if (
                  JSON.parse(contract.contracted_services).includes("hotel")
                ) {
                  JSON.parse(contract.meta.hotel_rows).forEach((hotel) => {
                    supplierlArr.push({
                      id: contract.id,
                      name: contract.meta[
                        `contract_finances_hotel_${hotel.id}_supplier`
                      ]
                        ? contract.meta[
                            `contract_finances_hotel_${hotel.id}_supplier`
                          ].toUpperCase()
                        : `NÃO PREENCHIDO`,
                      value:
                        contract.meta.package_type == "Serviços"
                          ? contract.meta[`hotel_${hotel.id}_total_price`]
                          : 0,
                      netValue:
                        contract.meta.package_type == "Serviços"
                          ? this.parseFloatToStr(
                              contract.meta[
                                `contract_finances_hotel_${hotel.id}_net_value`
                              ]
                            )
                          : 0,
                      profit:
                        contract.meta.package_type == "Serviços"
                          ? this.parseFloatToStr(
                              parse(
                                contract.meta[`hotel_${hotel.id}_total_price`]
                              ) -
                                parse(
                                  contract.meta[
                                    `contract_finances_hotel_${hotel.id}_net_value`
                                  ]
                                )
                            )
                          : 0,
                    });
                  });
                }
              }

              if (this.filters.productsCategory.selected.includes("flight")) {
                if (
                  JSON.parse(contract.contracted_services).includes("flight")
                ) {
                  JSON.parse(contract.meta.flight_sections_rows).forEach(
                    (flight) => {
                      let totalFlight = 0;

                      if (
                        contract.meta[`flight_${flight.id}_type`] == "Milhas"
                      ) {
                        let totalTravellersTicketValue = 0,
                          totalTravellersTaxesValue = 0;

                        flight.travellers.forEach((traveller) => {
                          totalTravellersTicketValue +=
                            contract.meta.package_type == "Serviços"
                              ? parse(
                                  contract.meta[
                                    `flight_${flight.id}_traveller_${traveller.id}_value`
                                  ]
                                )
                              : 0;

                          totalTravellersTaxesValue +=
                            contract.meta.package_type == "Serviços"
                              ? parse(
                                  contract.meta[
                                    `flight_${flight.id}_traveller_${traveller.id}_taxes`
                                  ]
                                )
                              : 0;
                        });

                        totalFlight +=
                          totalTravellersTaxesValue +
                          totalTravellersTicketValue;

                        supplierlArr.push({
                          id: contract.id,
                          name: `CREDIMILHAS`,
                          value:
                            contract.meta.package_type == "Serviços"
                              ? this.parseFloatToStr(
                                  totalTravellersTaxesValue +
                                    totalTravellersTicketValue
                                )
                              : 0,
                          netValue:
                            contract.meta[
                              `contract_finances_flight_${flight.id}_sale_value`
                            ],
                          profit: this.parseFloatToStr(
                            totalTravellersTaxesValue +
                              totalTravellersTicketValue -
                              contract.meta[
                                `contract_finances_flight_${flight.id}_sale_value`
                              ] -
                              totalTravellersTaxesValue
                          ),
                        });
                      }

                      if (
                        contract.meta[`flight_${flight.id}_type`] == "Regular"
                      ) {
                        let totalTravellersTicketValue = 0,
                          totalTravellersTaxesValue = 0,
                          totalTravellersDuTaxesValues = 0,
                          totalTravellersTebValues = 0;

                        flight.travellers.forEach((traveller) => {
                          totalTravellersTicketValue +=
                            contract.meta.package_type == "Serviços"
                              ? parse(
                                  contract.meta[
                                    `flight_${flight.id}_traveller_${traveller.id}_value`
                                  ]
                                )
                              : 0;

                          totalTravellersTaxesValue +=
                            contract.meta.package_type == "Serviços"
                              ? parse(
                                  contract.meta[
                                    `flight_${flight.id}_traveller_${traveller.id}_taxes`
                                  ]
                                )
                              : 0;

                          totalTravellersDuTaxesValues +=
                            contract.meta.package_type == "Serviços"
                              ? contract.meta[
                                  `flight_${flight.id}_traveller_${traveller.id}_du_tax`
                                ]
                                ? parse(
                                    contract.meta[
                                      `flight_${flight.id}_traveller_${traveller.id}_du_tax`
                                    ]
                                  )
                                : 0
                              : 0;

                          totalTravellersTebValues +=
                            contract.meta.package_type == "Serviços"
                              ? contract.meta[
                                  `flight_${flight.id}_traveller_${traveller.id}_teb_agency`
                                ]
                                ? parse(
                                    contract.meta[
                                      `flight_${flight.id}_traveller_${traveller.id}_teb_agency`
                                    ]
                                  )
                                : 0
                              : 0;
                        });

                        totalFlight +=
                          totalTravellersTicketValue +
                          totalTravellersTaxesValue +
                          totalTravellersDuTaxesValues +
                          totalTravellersTebValues;

                        supplierlArr.push({
                          id: contract.id,
                          name: contract.meta[
                            `contract_finances_flight_${flight.id}_supplier`
                          ]
                            ? contract.meta[
                                `contract_finances_flight_${flight.id}_supplier`
                              ].toUpperCase()
                            : `NÃO PREENCHIDO`,
                          value:
                            contract.meta.package_type == "Serviços"
                              ? this.parseFloatToStr(totalFlight)
                              : 0,
                          netValue: this.parseFloatToStr(
                            totalTravellersTicketValue
                          ),
                          profit: this.parseFloatToStr(
                            totalFlight - totalTravellersTicketValue
                          ),
                        });
                      }
                    }
                  );
                }
              }

              if (this.filters.productsCategory.selected.includes("service")) {
                JSON.parse(contract.meta.service_rows).forEach((service) => {
                  supplierlArr.push({
                    id: contract.id,
                    name: contract.meta[
                      `contract_finances_service_${service.id}_supplier`
                    ]
                      ? contract.meta[
                          `contract_finances_service_${service.id}_supplier`
                        ].toUpperCase()
                      : `NÃO PREENCHIDO`,
                    value:
                      contract.meta.package_type == "Serviços"
                        ? contract.meta[`service_${service.id}_total_price`]
                        : 0,
                    netValue:
                      contract.meta.package_type == "Serviços"
                        ? contract.meta[
                            `contract_finances_service_${service.id}_net_value`
                          ]
                        : 0,
                    profit:
                      contract.meta.package_type == "Serviços"
                        ? this.parseFloatToStr(
                            parse(
                              contract.meta[`service_${service.id}_total_price`]
                            ) -
                              parse(
                                contract.meta[
                                  `contract_finances_service_${service.id}_net_value`
                                ]
                              )
                          )
                        : 0,
                  });
                });
              }
            }
            //
          } else {
            if (contract.meta.package_type == "Pacote Completo Terceiros") {
              supplierlArr.push({
                id: contract.id,
                name: `PACOTE COMPLETO (${
                  contract.meta[`contract_finances_package_supplier`]
                })`,
                value: contract.meta[`package_total_price`],
                netValue: contract.meta[`contract_finances_package_net_value`],
                profit: this.parseFloatToStr(
                  parse(contract.meta[`package_total_price`]) -
                    parse(contract.meta[`contract_finances_package_net_value`])
                ),
              });
            } else {
              if (JSON.parse(contract.contracted_services).includes("hotel")) {
                JSON.parse(contract.meta.hotel_rows).forEach((hotel) => {
                  supplierlArr.push({
                    id: contract.id,
                    name: contract.meta[
                      `contract_finances_hotel_${hotel.id}_supplier`
                    ]
                      ? contract.meta[
                          `contract_finances_hotel_${hotel.id}_supplier`
                        ].toUpperCase()
                      : `NÃO PREENCHIDO`,
                    value:
                      contract.meta.package_type == "Serviços"
                        ? contract.meta[`hotel_${hotel.id}_total_price`]
                        : 0,
                    netValue:
                      contract.meta.package_type == "Serviços"
                        ? this.parseFloatToStr(
                            contract.meta[
                              `contract_finances_hotel_${hotel.id}_net_value`
                            ]
                          )
                        : 0,
                    profit:
                      contract.meta.package_type == "Serviços"
                        ? this.parseFloatToStr(
                            parse(
                              contract.meta[`hotel_${hotel.id}_total_price`]
                            ) -
                              parse(
                                contract.meta[
                                  `contract_finances_hotel_${hotel.id}_net_value`
                                ]
                              )
                          )
                        : 0,
                  });
                });
              }

              // FLIGHTS
              if (JSON.parse(contract.contracted_services).includes("flight")) {
                JSON.parse(contract.meta.flight_sections_rows).forEach(
                  (flight) => {
                    let totalFlight = 0;

                    if (contract.meta[`flight_${flight.id}_type`] == "Milhas") {
                      let totalTravellersTicketValue = 0,
                        totalTravellersTaxesValue = 0;

                      flight.travellers.forEach((traveller) => {
                        totalTravellersTicketValue +=
                          contract.meta.package_type == "Serviços"
                            ? parse(
                                contract.meta[
                                  `flight_${flight.id}_traveller_${traveller.id}_value`
                                ]
                              )
                            : 0;

                        totalTravellersTaxesValue +=
                          contract.meta.package_type == "Serviços"
                            ? parse(
                                contract.meta[
                                  `flight_${flight.id}_traveller_${traveller.id}_taxes`
                                ]
                              )
                            : 0;
                      });

                      totalFlight +=
                        totalTravellersTaxesValue + totalTravellersTicketValue;

                      supplierlArr.push({
                        id: contract.id,
                        name: `CREDIMILHAS`,
                        value:
                          contract.meta.package_type == "Serviços"
                            ? this.parseFloatToStr(
                                totalTravellersTaxesValue +
                                  totalTravellersTicketValue
                              )
                            : 0,
                        netValue:
                          contract.meta[
                            `contract_finances_flight_${flight.id}_sale_value`
                          ],
                        profit: this.parseFloatToStr(
                          totalTravellersTaxesValue +
                            totalTravellersTicketValue -
                            contract.meta[
                              `contract_finances_flight_${flight.id}_sale_value`
                            ] -
                            totalTravellersTaxesValue
                        ),
                      });
                    }

                    if (
                      contract.meta[`flight_${flight.id}_type`] == "Regular"
                    ) {
                      let totalTravellersTicketValue = 0,
                        totalTravellersTaxesValue = 0,
                        totalTravellersDuTaxesValues = 0,
                        totalTravellersTebValues = 0;

                      flight.travellers.forEach((traveller) => {
                        totalTravellersTicketValue +=
                          contract.meta.package_type == "Serviços"
                            ? parse(
                                contract.meta[
                                  `flight_${flight.id}_traveller_${traveller.id}_value`
                                ]
                              )
                            : 0;

                        totalTravellersTaxesValue +=
                          contract.meta.package_type == "Serviços"
                            ? parse(
                                contract.meta[
                                  `flight_${flight.id}_traveller_${traveller.id}_taxes`
                                ]
                              )
                            : 0;

                        totalTravellersDuTaxesValues +=
                          contract.meta.package_type == "Serviços"
                            ? contract.meta[
                                `flight_${flight.id}_traveller_${traveller.id}_du_tax`
                              ]
                              ? parse(
                                  contract.meta[
                                    `flight_${flight.id}_traveller_${traveller.id}_du_tax`
                                  ]
                                )
                              : 0
                            : 0;

                        totalTravellersTebValues +=
                          contract.meta.package_type == "Serviços"
                            ? contract.meta[
                                `flight_${flight.id}_traveller_${traveller.id}_teb_agency`
                              ]
                              ? parse(
                                  contract.meta[
                                    `flight_${flight.id}_traveller_${traveller.id}_teb_agency`
                                  ]
                                )
                              : 0
                            : 0;
                      });

                      totalFlight +=
                        totalTravellersTicketValue +
                        totalTravellersTaxesValue +
                        totalTravellersDuTaxesValues +
                        totalTravellersTebValues;

                      supplierlArr.push({
                        id: contract.id,
                        name: contract.meta[
                          `contract_finances_flight_${flight.id}_supplier`
                        ]
                          ? contract.meta[
                              `contract_finances_flight_${flight.id}_supplier`
                            ].toUpperCase()
                          : `NÃO PREENCHIDO`,
                        value:
                          contract.meta.package_type == "Serviços"
                            ? this.parseFloatToStr(totalFlight)
                            : 0,
                        netValue: this.parseFloatToStr(
                          totalTravellersTicketValue
                        ),
                        // totalFlight:
                        //     this.parseFloatToStr(
                        //         totalFlight
                        //     ),
                        profit: this.parseFloatToStr(
                          totalFlight - totalTravellersTicketValue
                        ),
                      });
                    }
                  }
                );
              }

              // SERVICES
              if (
                JSON.parse(contract.contracted_services).includes("service")
              ) {
                JSON.parse(contract.meta.service_rows).forEach((service) => {
                  // HOTEL ARR
                  supplierlArr.push({
                    id: contract.id,
                    name: contract.meta[
                      `contract_finances_service_${service.id}_supplier`
                    ]
                      ? contract.meta[
                          `contract_finances_service_${service.id}_supplier`
                        ].toUpperCase()
                      : `NÃO PREENCHIDO`,
                    value:
                      contract.meta.package_type == "Serviços"
                        ? contract.meta[`service_${service.id}_total_price`]
                        : 0,
                    netValue:
                      contract.meta.package_type == "Serviços"
                        ? contract.meta[
                            `contract_finances_service_${service.id}_net_value`
                          ]
                        : 0,
                    profit:
                      contract.meta.package_type == "Serviços"
                        ? this.parseFloatToStr(
                            parse(
                              contract.meta[`service_${service.id}_total_price`]
                            ) -
                              parse(
                                contract.meta[
                                  `contract_finances_service_${service.id}_net_value`
                                ]
                              )
                          )
                        : 0,
                  });
                });
              }
            }
          }
        });

        newArr = _.groupBy(supplierlArr, (product) => product.name);

        let keys = Object.keys(newArr),
          values = Object.values(newArr);

        // console.log("CHART ARR", keys, values);

        // FINAL CHART ARR
        for (var i = 0; i < keys.length; i++) {
          let total = 0,
            profit = 0,
            netValue = 0;

          values[i].forEach((product) => {
            // console.log("obj product", product);
            total += parse(product.value);
            profit += product.profit ? parse(product.profit) : 0;
            totalSale += parse(product.total);
            netValue += product.netValue ? parse(product.netValue) : 0;
          });

          totalNetValue += netValue;
          totalSale += total;
          totalProfit += profit;
          totalQuantity += values[i].length;
          totalValue += total / values[i].length;

          chartArr.push({
            name: `${keys[i]}`,
            value: values[i].length,
            total,
            profit,
            netValue,
            averageTicket: total / values[i].length,
          });
        }
      }

      this.chartOptions.series[0].data = chartArr;

      this.chartOptions.title.subtext =
        this.formatDate(this.filters.period.selected[0]) +
        " a " +
        this.formatDate(this.filters.period.selected[1]);

      setTimeout(() => {
        this.generateChart = true;
      }, 500);

      return {
        chartArr,
        newArr,
        totalQuantity,
        totalNetValue,
        totalProfit,
        totalSale,
        totalValue,
      };
    },
    generatePDF() {
      this.generatePDFLoading = true;

      let element = document.getElementById("print-area");
      let options = {
        filename: `Ranking de Fornecedores.pdf`,
        image: { type: "jpeg", quality: 1 },
        enableLinks: false,
        margin: [8, 8, 8, 8],
        html2canvas: { scale: 2 },
        jsPDF: {
          format: "a4",
          orientation: "landscape",
        },
        pagebreak: { mode: "avoid-all", after: ".avoidThisRow" },
      };

      html2pdf().from(element).set(options).save();

      this.generatePDFLoading = false;
    },
    changePage(current) {
      this.pagination.page = current;
      this.getContracts();
    },
    changePageSize(current, pageSize) {
      this.pagination.page = current;
      this.pagination.perPage = pageSize;
      this.getContracts();
    },
    contractsTableChange(pagination, filters, sorter) {
      this.filters.order = sorter.order != undefined ? sorter.order : "desc";
      this.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getContracts();
    },
    getContracts(samePage) {
      this.loading = true;
      this.generateChart = false;

      let filters = "";

      let queryParams = new URLSearchParams("");
      history.pushState(null, null, "");

      if (!this.$root.isAdmin() && !this.$root.isAnalyst()) {
        filters += `&user=${this.$store.state.userData.id}`;
      } else {
        if (this.filters.users.selected.length > 0) {
          filters += `&user=${this.filters.users.selected}`;
        }
      }
      if (this.filters.companies.selected.length > 0) {
        filters += `&company=${this.filters.companies.selected}`;
      }

      if (this.filters.status.selected.length > 0) {
        filters += `&status=${this.filters.status.selected}`;
      }

      if (this.filters.saleType.selected.length > 0) {
        filters += `&sale-type=${this.filters.saleType.selected}`;
      }

      if (this.filters.paymentMethods.selected.length > 0) {
        filters += `&payment-methods=${this.filters.paymentMethods.selected}`;
      }

      if (this.filters.personTypes.selected.length > 0) {
        filters += `&person-type=${this.filters.personTypes.selected}`;
      }

      if (this.filters.productsCategory.selected.length > 0) {
        filters += `&product-categories=${this.filters.productsCategory.selected}`;
      }

      if (this.filters.embark.selected.length > 0) {
        filters += `&embark=${this.filters.embark.selected[0]}|${this.filters.embark.selected[1]}`;
      }

      if (this.filters.period.selected.length > 0) {
        filters += `&period=${this.filters.period.selected[0]}|${this.filters.period.selected[1]}`;
        queryParams.set(
          "period",
          `${this.filters.period.selected[0]}|${this.filters.period.selected[1]}`
        );
      }

      if (this.filters.price.min || this.filters.price.max) {
        let min = this.filters.price.min;
        let max = this.filters.price.max;

        if (min) {
          if (!max) {
            max = "500000";
          }
        }

        if (max) {
          if (!min) {
            min = "0";
          }
        }

        filters += `&price-range=${min}-${max}`;
      }

      if (this.filters.searchTerm) {
        filters += `&s=${this.filters.searchTerm}`;
      }

      if (this.filters.id) {
        filters += `&id=${this.filters.id}`;
      }

      filters += `&order=${this.filters.order}&order-by=${this.filters.orderBy}`;

      history.replaceState(null, null, "?" + queryParams.toString());

      this.$http
        .get(
          `/contract-v2/list?page=${this.pagination.page}&per_page=${this.pagination.perPage}${filters}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.pagination.page = 1;
          }
          this.contractList = data.data;
          this.generateSuppliersData();
          this.loading = false;
        })
        .catch(({ response }) => {
          this.contractList = [];
          this.generateSuppliersData();
          this.loading = false;
          response;
        });
    },
    getUsers() {
      this.$http
        .get("/user/read")
        .then(({ data }) => {
          this.filters.users.list = data.map((user) => {
            return {
              label: `${user.id} - ${user.first_name} ${user.last_name}`,
              value: user.id,
            };
          });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.chart
  height: 700px
  width: 700px !important
  margin: 0 auto
</style>

<style>
.ant-table-tbody > tr > td.ant-table-column-sort {
  background: #fff !important;
}

.chart-table td {
  padding: 5px 0 !important;
  font-size: 11px !important;
}

.chart-table th {
  padding: 5px 0 !important;
  font-size: 11px !important;
  background: #ffffff !important;
}

.chart-table .ant-table-footer {
  font-size: 11px !important;
  padding: 0;
  background: #fafafa;
  border-top: 1px solid #e8e8e8;
}
</style>
